import { render, staticRenderFns } from "./CustomFilterForProfitLoss.vue?vue&type=template&id=4b0ccc79&"
import script from "./CustomFilterForProfitLoss.vue?vue&type=script&lang=js&"
export * from "./CustomFilterForProfitLoss.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports