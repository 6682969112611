<template>
  <div>
    <!-- <v-form> -->
    <!-- <span class="f-inter fs-12">Date: {{ dateValue }}</span> -->
    <v-select
      dense
      outlined
      :items="items"
      item-text="value"
      item-value="key"
      v-model="selectedValue"
      @change="setSelectedValue()"
      hide-details
    />
    <!-- </v-form> -->
    <v-dialog
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      width="290px"
    >
      <v-date-picker v-model="dates" scrollable range>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="revertSelectedOption()">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="saveDate()"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateFilter from "../../../js/DateFilters.js";
export default {
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: [],
      menu: false,
      selectedValue: "",
      items: [
        { key: "thisMonth", value: "This Month" },
        { key: "thisQuarter", value: "This Quarter" },
        { key: "previousMonth", value: "Last month" },
        { key: "previousQuarter", value: "Last quarter" },
        { key: "endOfLastFinancialYear", value: "Last financial year" },
        { key: "monthToDate", value: "Month To Date" },
        { key: "quarterToDate", value: "Quarter To Date" },
        { key: "yearToDate", value: "Year To Date" },
        { key: "customDate", value: "Custom Date Range" },
      ],
      dateValue: "",
    };
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
  },
  mounted() {
    this.selectedValue = "thisMonth";
    this.setSelectedValue();
  },

  methods: {
    saveDate() {
      console.log(this.dates, "Date");
      this.$emit("selectedDates", {
        startDate: this.dates[0],
        endDate: this.dates[1],
      });
      this.menu = false;
      this.dates = [];
      console.log(this.dates, "Date");
    },
    revertSelectedOption() {
      this.menu = false;
      this.selectedValue = "thisMonth";
      this.setSelectedValue();
    },
    async setSelectedValue() {
      if (this.selectedValue != "customDate") {
        const selectedDate = await dateFilter.setDateFilter(this.selectedValue);
        let start, end;
        if (
          this.selectedValue == "thisMonth" ||
          this.selectedValue == "previousQuarter"
        ) {
          start = new Date(selectedDate.startDate + "Z")
            .toISOString()
            .substring(0, 10);
          end = new Date(selectedDate.endDate + "Z")
            .toISOString()
            .substring(0, 10);
        } else if (this.selectedValue == "endOfLastFinancialYear") {
          start = selectedDate.startDate;
          end = selectedDate.endDate;
        } else if (this.selectedValue == "monthToDate") {
          start = new Date(selectedDate.startDate + "Z")
            .toISOString()
            .substring(0, 10);
          end = new Date(selectedDate.endDate).toISOString().substring(0, 10);
        } else if (this.selectedValue == "thisQuarter") {
          start = new Date(selectedDate.startDate)
            .toISOString()
            .substring(0, 10);
          end = new Date(selectedDate.endDate + "Z")
            .toISOString()
            .substring(0, 10);
        } else {
          start = new Date(selectedDate.startDate)
            .toISOString()
            .substring(0, 10);
          end = new Date(selectedDate.endDate).toISOString().substring(0, 10);
        }
        console.log("start", start, "end", end);

        this.$emit("selectedDates", {
          startDate: start,
          endDate: end,
        });
      } else if (this.selectedValue == "customDate") {
        this.menu = true;
      }
    },
  },
};
</script>

<style></style>
